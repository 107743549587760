<template>
  <div>
    <el-page-header :content="$t('fence.device')+' ['+imei+'] '+$t('health.title')" @back="goBack"
                    style="margin-bottom:10px"></el-page-header>
    <el-card class="box-card">
      <div class="tempcont">
        <h3>{{ $t('detail.temp') }} / ℃</h3>
        <el-date-picker
            @change="tempChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="tempDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="tempChart chart"
            ref="tempChart"
            v-if="tempData !== undefined && tempData.length > 0"
        ></div>
        <el-empty :description="$t('health.no_data')" v-else/>
      </div>
      <div class="breathcont">
        <h3>{{ $t('health.breathe') }} / {{ $t('health.frequency') }}</h3>
        <el-date-picker
            @change="breathChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="breathDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="breathChart chart"
            ref="breathChart"
            v-if="breathData !== undefined && breathData.length > 0"
        ></div>
        <el-empty :description="$t('health.no_data')" v-else></el-empty>
      </div>
      <div class="heartcont">
        <h3>{{ $t('detail.heart') }} / {{ $t('health.times') }}</h3>
        <el-date-picker
            @change="heartChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="heartDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="heartChart chart"
            ref="heartChart"
            v-if="heartData !== undefined && heartData.length > 0"
        ></div>
        <el-empty :description="$t('health.no_data')" v-else></el-empty>
      </div>
      <div class="oxygencont">
        <h3>{{ $t('detail.oxygen') }}</h3>
        <el-date-picker
            @change="oxygenChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="oxygenDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="oxygenCharts chart"
            ref="oxygenCharts"
            v-if="oxygenData !== undefined && oxygenData.length > 0"
        ></div>
        <el-empty :description="$t('health.no_data')" v-else></el-empty>
      </div>
      <div class="bloodcont">
        <h3>{{ $t('detail.blood') }}/bpm</h3>
        <el-date-picker
            @change="bloodChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="bloodDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="bloodChart chart"
            ref="bloodChart"
            v-if="bloodDia !== undefined && bloodDia.length > 0"
        ></div>
        <el-empty :description="$t('health.no_data')" v-else></el-empty>
      </div>
      <div class="hrvcont">
        <h3>HRV/{{ $t('detail.pressure') }}</h3>
        <el-date-picker
            @change="pressureChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="pressureDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="pressureChart chart"
            ref="pressureChart"
            v-if="pressureData !== undefined && pressureData.length > 0"
        ></div>
        <el-empty description="暂无数据" v-else></el-empty>
        <div>
          <div style="width: 500px;height: 50px;margin-left: 25%;margin-top: 20px;overflow: hidden">
            <div style="width: 40%;background-color: #5BC852;float: left;height: 30px;border-radius: 20px 0 0 20px;">
            </div>
            <div style="width: 20%;background-color: #49ACF4;float: left;height: 30px">
            </div>
            <div style="width: 20%;background-color: #FEC106;float: left;height: 30px">
            </div>
            <div style="width: 20%;background-color: #FC7646;float: left;height: 30px;border-radius: 0 20px 20px 0;">
            </div>
            <div style="width: 40%;float: left;height: 20px">
              <div style="color: #5BC852;width: 80%;float: left;text-align: center">轻松</div>
              <div style="color: #5BC852;text-align: right">40</div>
            </div>
            <div style="width: 20%;float: left;height: 20px">
              <div style="color: #49ACF4;width: 80%;float: left;text-align: center">低压力</div>
              <div style="color: #49ACF4;text-align: right">60</div>
            </div>
            <div style="width: 20%;float: left;height: 20px">
              <div style="color: #FEC106;width: 80%;float: left;text-align: center">中压力</div>
              <div style="color: #FEC106;text-align: right">80</div>
            </div>
            <div style="width: 20%;float: left;height: 20px">
              <div style="color: #FC7646;width: 75%;float: left;text-align: center">高压力</div>
              <div style="color: #FC7646;text-align: right">100</div>
            </div>
          </div>
        </div>
      </div>
      <div class="stepcont">
        <h3>{{ $t('detail.exercise') }}</h3>
        <el-date-picker
            @change="stepChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="stepDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <div
            :style="{width: '100%', height: '300px'}"
            class="stepChart chart"
            ref="stepChart"
            v-if="stepData !== undefined && stepData.length > 0"
        ></div>
        <el-empty :description="$t('health.node_data')" v-else></el-empty>
      </div>
      <div class="sleepcont">
        <h3>{{ $t('health.sleep') }}</h3>
        <el-date-picker
            @change="sleepChartFun"
            :placeholder="$t('health.select_date')"
            type="date"
            v-model="sleepDate"
            :disabled-date="disabledDate"
            value-format="YYYY-MM-DD"
        ></el-date-picker>
        <span style="padding-left: 20px">{{ $t('device.start_time') }}：{{ sleep_start_time }}</span>
        <span style="padding-left: 20px">{{ $t('device.end_time') }}：{{ sleep_end_time }}</span>
        <span style="padding-left: 20px">{{ $t('health.sleep_duration') }}：{{ sleep_duration }}</span>
        <div style="margin-top: 20px;">
          <span style="color: blueviolet">{{ $t('health.shallow') }}：{{ light_duration }}</span>
          <span style="padding-left: 20px;color: darkorchid">{{ $t('health.deep') }}：{{ deep_duration }}</span>
          <span style="padding-left: 20px;color: #a065ff">{{ $t('health.clear') }}：{{ wake_duration }}</span>
        </div>
        <div
            :style="{width: '100%', height: '300px'}"
            class="sleepChart chart"
            ref="sleepChart"
            v-if="sleepData !== undefined && sleepData.length > 0"
        ></div>
        <el-empty :description="$t('health.no_data')" v-else></el-empty>
      </div>
    </el-card>
  </div>
</template>
<script>
import echarts from "../../utils/echarts";
import data from "@/model/data";

export default {
  name: "health",
  data() {
    return {
      imei: this.$route.query.imei,
      tempDate: "",
      heartDate: "",
      stepDate: "",
      sleepDate: "",
      bloodDate: "",
      pressureDate: "",
      oxygenDate: "",
      breathDate: "",
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ],
      tempData: [],
      tempTime: [],
      heartData: [],
      heartTime: [],
      stepData: [],
      stepTime: [],
      sleepData: [],
      sleepTime: [],
      bloodDia: [],
      bloodSys: [],
      bloodTime: [],
      pressureData: [],
      pressureTime: [],
      breathData: [],
      oxygenData: [],
      breathTime: [],
      wake_duration: this.$t('health.no_data'),
      light_duration: this.$t('health.no_data'),
      deep_duration: this.$t('health.no_data'),
      sleep_duration: this.$t('health.no_data'),
      sleep_start_time: this.$t('health.no_data'),
      sleep_end_time: this.$t('health.no_data'),
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    };
  },
  async mounted() {
    await this.tempChartFun();
    await this.breathChartFun();
    await this.heartChartFun();
    await this.oxygenChartFun();
    await this.stepChartFun();
    await this.sleepChartFun();
    await this.bloodChartFun();
    await this.pressureChartFun();
  },
  methods: {
    async tempChartFun() {
      const ret = await data.temp({
        imei: this.imei,
        date: this.tempDate
      });
      if (ret.code === 200) {
        this.tempData = ret.data.temp;
        this.tempTime = ret.data.time;
        if (this.tempData !== undefined && this.tempData.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });
          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.tempChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              calculable: true,
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: this.tempTime
                }
              ],
              yAxis: [
                {
                  type: "value",
                  axisLabel: {
                    formatter: "{value} °C"
                  },
                  splitNumber: 1,
                  max: ret.data.max,
                  min: ret.data.min,
                  interval: 1, //每次增加几个
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: "dashed"
                    }
                  }
                }
              ],
              series: [
                {
                  name: this.$t('detail.temp'),
                  type: "line",
                  data: this.tempData,
                  markPoint: {
                    data: [
                      {type: "max", name: this.$t('group.max')},
                      {type: "min", name: this.$t('group.min')}
                    ]
                  },
                  itemStyle: {
                    normal: {
                      color: "#a7e854", //折线点的颜色
                      lineStyle: {
                        color: "#a7e854" //折线的颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async breathChartFun() {
      const ret = await data.breath({
        imei: this.imei,
        date: this.breathDate
      });
      if (ret.code === 200) {
        this.breathData = ret.data.number;
        this.breathTime = ret.data.time;
        if (this.breathData !== undefined && this.breathData.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });
          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.breathChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              calculable: true,
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: this.breathTime
                }
              ],
              yAxis: [
                {
                  type: "value",
                  axisLabel: {
                    formatter: "{value} " + this.$t('health.times')
                  },
                  splitNumber: 1,
                  max: ret.data.max,
                  min: 0,
                  interval: 5, //每次增加几个
                  splitLine: {
                    show: true,
                    lineStyle: {
                      type: "dashed"
                    }
                  }
                }
              ],
              series: [
                {
                  name: this.$t('health.frequency'),
                  type: "line",
                  data: this.breathData,
                  markPoint: {
                    data: [
                      {type: "max", name: this.$t('group.max')},
                      {type: "min", name: this.$t('group.min')}
                    ]
                  },
                  itemStyle: {
                    normal: {
                      color: "#a7e854", //折线点的颜色
                      lineStyle: {
                        color: "#a7e854" //折线的颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async heartChartFun() {
      const ret = await data.heart({
        imei: this.imei,
        date: this.heartDate
      });
      if (ret.code === 200) {
        this.heartData = ret.data.number;
        this.heartTime = ret.data.time;
        if (this.heartData !== undefined && this.heartData.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });
          //然后异步执行echarts的初始化函数
          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.heartChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: this.heartTime
              },
              yAxis: {
                type: "value"
              },
              series: [
                {
                  name: this.$t('detail.heart'),
                  data: this.heartData,
                  type: "line",
                  areaStyle: {
                    normal: {
                      color: "#a7e854" //改变区域颜色
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: "#a7e854", //改变折线点的颜色
                      lineStyle: {
                        color: "#a7e854" //改变折线颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async oxygenChartFun() {
      const ret = await data.oxygen({
        imei: this.imei,
        date: this.oxygenDate
      });
      if (ret.code === 200) {
        this.oxygenData = ret.data.number;
        this.oxygenTime = ret.data.time;
        if (this.oxygenData !== undefined && this.oxygenData.length > 0) {
          let newPromise = new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 500)
          });
          //然后异步执行echarts的初始化函数
          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.oxygenCharts);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: this.oxygenTime
              },
              yAxis: {
                type: "value"
              },
              series: [
                {
                  name: this.$t('detail.oxygen'),
                  data: this.oxygenData,
                  type: "line",
                  areaStyle: {
                    normal: {
                      color: "#a7e854" //改变区域颜色
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: "#a7e854", //改变折线点的颜色
                      lineStyle: {
                        color: "#a7e854" //改变折线颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async stepChartFun() {
      const ret = await data.step({
        imei: this.imei,
        date: this.stepDate
      });
      if (ret.code === 200) {
        this.stepData = ret.data.number;
        this.stepTime = ret.data.time;
        if (this.stepData !== undefined && this.stepData.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });
          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.stepChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: this.stepTime
              },
              yAxis: {
                type: "value"
              },
              series: [
                {
                  name: this.$t('detail.step'),
                  data: this.stepData,
                  type: "line",
                  areaStyle: {
                    normal: {
                      color: "#a7e854" //改变区域颜色
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: "#a7e854", //改变折线点的颜色
                      lineStyle: {
                        color: "#a7e854" //改变折线颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async sleepChartFun() {
      const ret = await data.sleep({
        imei: this.imei,
        date: this.sleepDate
      });
      if (ret.code === 200) {
        this.sleepData = ret.data.number;
        this.sleepTime = ret.data.time;
        this.wake_duration = ret.data.wake_duration;
        this.light_duration = ret.data.light_duration;
        this.deep_duration = ret.data.deep_duration;
        this.sleep_duration = ret.data.sleep_duration;
        this.sleep_start_time = ret.data.start_time;
        this.sleep_end_time = ret.data.end_time;
        if (this.sleepData !== undefined && this.sleepData.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });
          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.sleepChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: this.sleepTime
              },
              yAxis: {
                type: "category",
                data: [this.$t('health.clear_sleep'), this.$t('health.shallow_sleep'), this.$t('health.deep_sleep')]
              },
              series: [
                {
                  name: this.$t('health.sleep_status'),
                  data: this.sleepData,
                  type: "line",
                  smooth: true,
                  areaStyle: {
                    normal: {
                      color: "#a7e854" //改变区域颜色
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: "#a7e854", //改变折线点的颜色
                      lineStyle: {
                        color: "#a7e854" //改变折线颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async bloodChartFun() {
      const ret = await data.blood({
        imei: this.imei,
        date: this.bloodDate
      });
      if (ret.code === 200) {
        this.bloodDia = ret.data.diastolic;
        this.bloodSys = ret.data.systolic;
        this.bloodTime = ret.data.time;
        if (this.bloodDia !== undefined && this.bloodDia.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });

          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.bloodChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              legend: {
                data: [this.$t('detail.systolic'), this.$t('detail.diastolic')]
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: this.bloodTime
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  formatter: "{value}"
                },
                splitNumber: 1,
                max: ret.data.max,
                min: 40,
                interval: 10, //每次增加几个
                splitLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed"
                  }
                }
              },
              series: [
                {
                  name: this.$t('detail.systolic'),
                  type: "line",
                  data: this.bloodDia,
                  itemStyle: {
                    normal: {
                      color: "#A7E854", //折点颜色
                      lineStyle: {
                        color: "#A7E854" //折线颜色
                      }
                    }
                  }
                },
                {
                  name: this.$t('detail.diastolic'),
                  type: "line",
                  data: this.bloodSys,
                  markLine: {
                    data: [
                      {
                        symbol: "none",
                        x: "90%",
                        yAxis: "max"
                      }
                    ]
                  },
                  itemStyle: {
                    normal: {
                      color: "#e85268", //折点颜色
                      lineStyle: {
                        color: "#E85268" //折线颜色
                      }
                    }
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    async pressureChartFun() {
      const ret = await data.pressure({
        imei: this.imei,
        date: this.pressureDate
      });
      if (ret.code === 200) {
        this.pressureData = ret.data.number;
        this.pressureTime = ret.data.time;
        if (this.pressureData !== undefined && this.pressureData.length > 0) {
          let newPromise = new Promise(resolve => {
            resolve();
          });

          newPromise.then(() => {
            let myEchart = echarts.init(this.$refs.pressureChart);
            var option = {
              tooltip: {
                trigger: "axis"
              },
              xAxis: {
                // type: "category",
                // boundaryGap: false,
                data: this.pressureTime
              },
              yAxis: {},
              visualMap: {
                top: 50,
                right: 10,
                pieces: [
                  {
                    gte: 0,
                    lt: 40,
                    color: '#5BC852'
                  },
                  {
                    gte: 40,
                    lt: 60,
                    color: '#49ACF4'
                  },
                  {
                    gte: 60,
                    lt: 80,
                    color: '#FEC106'
                  },
                  {
                    gte: 80,
                    lte: 100,
                    color: '#FC7646'
                  }
                ]
              },
              series: [
                {
                  name: this.$t('detail.hrv'),
                  data: this.pressureData,
                  type: "line",
                  markLine: {
                    silent: true,
                    lineStyle: {
                      color: '#333'
                    },
                    data: [
                      {
                        yAxis: 40
                      },
                      {
                        yAxis: 60
                      },
                      {
                        yAxis: 80
                      },
                      {
                        yAxis: 100
                      }
                    ]
                  }
                }
              ]
            };
            myEchart.setOption(option);
          });
        }
      }
    },
    tempChange() {
      this.tempChartFun();
    },
    goBack() {
      this.$router.replace({
        path: "/data/home",
        query: {
          imei: this.imei
        }
      })
    }
  }
};
</script>
<style scoped>
.chart {
  margin-top: 20px;
}

h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
